const overlay = document.querySelector('#mover-app-loader-overlay');
const app = document.querySelector('upd-micro-app-container');

const hideLoader = () => {
  // This code needs to be idempotent because the fallback and event listener
  // will typically both call this function once
  overlay.setAttribute('hidden', '');
  app.removeAttribute('hidden');
};

const showLoader = () => {
  overlay.removeAttribute('hidden');
  app.setAttribute('hidden', '');
};

overlay.debug = {
  showLoader,
  hideLoader,
};
overlay.setAttribute('debug', '');

app.addEventListener('upd-app-loaded', hideLoader);
app.addEventListener('upd-app-loading', showLoader);

// Fallback if the event never fires. Remove loader within 15 seconds.
setTimeout(hideLoader, 15000);
